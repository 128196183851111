<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loadingState">
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-overlay :show="loadingState">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="infoData.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <!-- <ValidationProvider name="Tag" vid="tag_id" rules="required|min_value:1"> -->
                            <ValidationProvider name="Tag" vid="tag_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="tag_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('VirtualMuseum.configuration.select_information_menu')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="infoData.tag"
                                :options="tagList"
                                id="tag_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="blankVal" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Name En" vid="name_en" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="name_en"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('VirtualMuseum.configuration.name_en')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="name_en"
                                v-model="infoData.name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="name Bn" vid="name_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="name_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('VirtualMuseum.configuration.name_bn')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="name_bn"
                                v-model="infoData.name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Upload Image" vid="upload_image_id" :rules="`${infoData.id ? '' : 'required'}`">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="upload_image_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('content_management.upload_image')}} <span v-if="infoData.id==null" class="text-danger">*</span>
                                </template>
                                <b-form-file
                                id="upload_image_id"
                                @change="processFile"
                                v-model="infoImgFile"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-file>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                                <a v-if="id && infoData.info_image"
                                    class="btn btn-sm btn-success mt-2" target='_blank'
                                    :href="virtualMuseumServiceBaseUrl + 'info-image/' + infoData.info_image">
                                    <img style="height: 100px; width: 150px;" class="list-image-layout"
                                          :src="virtualMuseumServiceBaseUrl + 'info-image/' + infoData.info_image"
                                          alt="Crop Type Image">
                                </a>
                            </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-3')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                    </b-overlay>
                </ValidationObserver>
              </b-col>
            </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { infomenuSaveApi, infomenuListOrgWiseApi } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getInfoMenuData()
      this.infoData = tmp
      this.infoData.edit_id = this.id
      this.getOrgWiseMenu()
      // this.infoData.edit_id = this.id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        loadingState: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        virtualMuseumServiceBaseUrl,
        infoData: {
            org_id: 0,
            tag: '',
            name: '',
            name_bn: '',
            info_image: '',
            edit_id: ''
        },
        infoImgFile: [],
        blankVal: '',
        tagList: [],
        tempImg: ''
    }
  },
  watch: {
    'infoData.org_id': function (newVal, oldVal) {
      if ((newVal !== oldVal)) {
          this.getOrgWiseMenu()
        }
    }
  },
  computed: {
      orgList: function () {
          const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      },
      allTagList: function () {
            const list = [
            { value: 'soilInfo', text: this.$i18n.locale === 'en' ? 'Soil Information' : 'মাটির তথ্য' },
            { value: 'pestInfo', text: this.$i18n.locale === 'en' ? 'Pest Description' : 'রোগবালাইয়ের তথ্য' },
            { value: 'cropInfo', text: this.$i18n.locale === 'en' ? 'Crop Description' : 'ফসল তথ্য' },
            { value: 'galleryInfo', text: this.$i18n.locale === 'en' ? 'Gallery Information' : 'গ্যালারী তথ্য' }
            ]
           return list
        }
  },
  methods: {
    processFile (event) {
        const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.tempImg = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.tempImg = ''
        }
    },
    getInfoMenuData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.loadingState = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      const formData = Object.assign(this.infoData, { info_image: this.tempImg })
      result = await RestApi.postData(virtualMuseumServiceBaseUrl, infomenuSaveApi, formData)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
            this.loadingState = false
            this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })

            this.$bvModal.hide('modal-3')
      } else {
            this.$refs.form.setErrors(result.errors)
      }
    },
    async getOrgWiseMenu () {
        this.loadingState = true
        let result = null
        const params = Object.assign({}, { org_id: this.infoData.org_id })
        result = await RestApi.postData(virtualMuseumServiceBaseUrl, infomenuListOrgWiseApi, params)
        this.loadingState = false
        if (result.success) {
            if (result.data.length > 0) {
               if (this.id) {
                 var selectedTag = this.infoData.tag
                  this.tagList = this.allTagList.filter(function (e) {
                    return !result.data.includes(e.value) || e.value === selectedTag
                  })
               } else {
                 this.tagList = this.allTagList.filter(function (e) {
                    return !result.data.includes(e.value)
                  })
               }
            } else {
              this.tagList = this.allTagList
            }
        }
    }
  }
}
</script>
